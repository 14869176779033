.login-main {
    display: flex;
    align-items: flex-start;
    height: 100vh;
    .login-box {
        height: 100%;
        overflow-y: auto;
        width: 50%;
        padding: 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        scrollbar-width: none;
        .logo_img {
            width: 173px;
            cursor: pointer;
        }
        .login-form {
            position: relative;
            width: 360px;
            margin: 0 auto;
            padding-top: 112px;
            &.ptSign {
                padding-top: 161px;
            }
            &.ptJoin {
                padding-top: 297px;
            }
            h1 {
                width: 360px;
                font-family: NeuethingSansTest-SemiBoldExpanded;
                font-weight: normal;
                font-size: 30px;
                color: #fdfdfd;
                line-height: 38px;
                text-align: left;
                margin: 0 0 12px 0;
            }
            .formTop_tip {
                width: 360px;
                font-family: NeuethingSansTest;
                font-weight: 400;
                font-size: 16px;
                color: #ededed;
                line-height: 24px;
                text-align: left;
                margin-bottom: 53px;
            }
            .r-input,
            .r-primary_btn {
                width: 100%;
            }
            .r-primary_btn {
                margin: 24px 0;
            }
            .error-tip {
                margin-top: 6px;
                color: #f04438;
                font-size: 14px;
                font-family: NeuethingSansTest-RegularExpanded;
            }
            .policy-tip {
                font-family: NeuethingSansTest;
                font-weight: 400;
                font-size: 12px;
                color: #ededed;
                opacity: 0.45;
                // color: #8d9081;
                line-height: 18px;
                text-align: left;
                opacity: 0.45;
                a {
                    text-decoration: underline;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
            .inputMar9 {
                margin: 9px 0;
            }
            .mart9 {
                margin-top: 9px;
            }
        }
        .prompt-message {
            color: #d7d7d7;
            text-align: center;
            font-size: 14px;
            font-family: Regular Expanded;
            margin-top: 50px;
            .login-btn {
                color: #c5f600;
                margin-left: 10px;
                font-family: Regular Expanded;
                font-size: 12px;
            }
        }
        .top-clubsBox {
            margin-top: 130px;
            &.mtComp {
                margin-top: 40px;
            }
            &.signMt {
                margin-top: 180px;
            }
            .club_tip {
                text-align: center;
                font-family: NeuethingSansTest-Meduim;
                font-weight: 400;
                font-size: 8px;
                color: #b8bab0;
                line-height: 20px;
                margin-bottom: 24px;
            }
            .clubs_img {
                width: 61px;
                height: 61px;
                margin-right: 20px;
            }
            .clubImg_box:last-child {
                .clubs_img {
                    margin: 0;
                }
            }
        }
    }
    .SignImg-right {
        width: 50%;
        height: 100%;
        object-fit: cover;
    }
}
.linkBox {
    cursor: pointer;
    text-decoration: underline !important;
    text-decoration-color: #ededed !important;
    color: #ededed !important;
    font-family: NeuethingSansTest;
    font-size: 12px;
    font-weight: 400;
}
.mb-main {
    .login-main {
        height: auto;
        overflow: hidden;
        align-items: flex-start;
        .login-box {
            min-height: 100vh;
            height: 100%;
            overflow-y: auto;
            width: 100%;
            padding: 24px 16px 36px 16px;
            display: flex;
            flex-direction: column;
            scrollbar-width: none;
            .logo_img {
                width: 132px;
            }
            .login-form {
                width: 100%;
                margin: 0 auto;
                padding-top: 1.31rem;
                &.ptSign {
                    padding-top: 1.32rem;
                }
                &.ptJoin {
                    padding-top: 1.31rem;
                }
                h1 {
                    width: 100%;
                    font-family: NeuethingSansTest-SemiBoldExpanded;
                    font-weight: normal;
                    font-size: 24px;
                    color: #fdfdfd;
                    line-height: 32px;
                    text-align: left;
                    margin: 0 0 16px 0;
                }
                .formTop_tip {
                    width: 100%;
                    font-family: NeuethingSansTest;
                    font-weight: 400;
                    font-size: 16px;
                    color: #ededed;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 32px;
                }
                .r-input,
                .r-primary_btn {
                    width: 100%;
                }
                .r-primary_btn {
                    margin: 24px 0;
                }
                .policy-tip {
                    font-family: NeuethingSansTest;
                    font-weight: 400;
                    font-size: 12px;
                    color: #ededed;
                    opacity: 0.45;
                    // color: #8d9081;
                    line-height: 18px;
                    text-align: left;
                }
                .inputMar9 {
                    margin: 24px 0;
                }
                .mart9 {
                    margin-top: 24px;
                }
            }
            .top-clubsBox {
                margin-top: auto;
                &.mtComp {
                    margin-top: 0.4rem;
                }
                .club_tip {
                    text-align: center;
                    font-family: NeuethingSansTest-Meduim;
                    font-weight: 400;
                    font-size: 8px;
                    // color: #FFFFFF;
                    color: #b8bab0;
                    line-height: 20px;
                    margin-bottom: 24px;
                }
                .clubImg_box {
                    font-size: 16px;
                }
                .clubs_img {
                    width: 61px;
                    height: 61px;
                    margin-right: 20px;
                }
                .clubImg_box:nth-child(4) {
                    .clubs_img {
                        margin: 0;
                    }
                }
            }
        }
        .SignImg-right {
            display: none;
            width: 50%;
            height: 100%;
            object-fit: cover;
        }
    }
}
